import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from "react-router";
import { ILoginPayload, loginUser } from "../../../store/Userzamzam";
import { unwrapResult } from '@reduxjs/toolkit';

import { ToastContainer, toast } from 'react-toastify';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { useLayoutEffect, useState } from 'react'
import { useAppDispatch } from "../../../store/Hook";
import IconButton from '@mui/material/IconButton';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';


const {
  REACT_APP_WEBAPP_VERSION,
  REACT_APP_LATEST_BUILD_VERSION,
  REACT_APP_LATEST_BUILD_TIMESTAMP
} = process.env

function SignInSide() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { control, handleSubmit, formState: { errors, isSubmitting } } = useForm<ILoginPayload>({
    defaultValues: {}
  });
  const dispatch = useAppDispatch();





  const onSubmit = (data) => {
    dispatch(loginUser(data))
      .then(unwrapResult)
      .then((v) => {
        localStorage.setItem("uniKey", v.access_token)
        navigate('/');
        navigate(0)
      })
      .catch((obj) => {
        if (obj.code === "ERR_BAD_REQUEST") {
          toast("User Name atau Password Salah. Silahkan Ulangi", { type: toast.TYPE.ERROR, autoClose: 3000, position: "top-center" })
        } else {
          toast("Server belum tersedia, mohon tunggu beberapa saat.", {
            type: toast.TYPE.ERROR,
            autoClose: 3000,
            position: "top-center"
          });
        }
      })
      ;

  }
  const onError = (error, e) => {
    console.log('---error--', error, e)
  }

  useLayoutEffect(() => {

    document.body.style.backgroundImage = "linear-gradient(black, violet)"
    document.body.style.height = "600px"
  });

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8
        }}
      >
        <Grid container id="xxx" style={{ backgroundColor: "#543226!important" }}>
          <CssBaseline />
          {/* <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
                backgroundImage: "url(/static/images/logo/new_retali.png)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900], 
                  backgroundColor:"#1c2026",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          /> */}
          <Grid
            item
            xs={false}
            sm={4}
            md={7}

            sx={{
              backgroundColor: "#1c2026",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <a href="https://retali.id/" target="_blank" style={{ textAlign: "center" }} rel="noreferrer" >
                {/* <img alt="Retali" src="/static/images/logo/zamzam-logo.png" style={{ width: "80%" }} */}
                <img alt="Retali" src="/static/images/logo/retali.png" style={{ width: "80%" }}
                /></a>
              <h1 style={{ color: "#ffffff", fontFamily: "Roboto Condensed, Sans-serif", textAlign: "center" }}>
                Hajj & Umrah <span style={{ color: "#ff0" }}>Travel Application</span>
              </h1>
              <a href="https://bisnisin.asia/" target="_blank" style={{ textDecoration: "none" }} rel="noreferrer">
                <h3 style={{ paddingTop: "1px", color: "#8d0788", fontFamily: "Roboto Condensed, Sans-serif" }}>
                  bisnisin.asia
                </h3>
              </a>
              {/* <a href="https://retali.id/" target="_blank" style={{ textDecoration: "none" }} rel="noreferrer">
                <h3 style={{ paddingTop: "1px", color: "#8d0788", fontFamily: "Roboto Condensed, Sans-serif" }}>
                  retali.id
                </h3>
              </a> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h6" style={{ textAlign: "center" }}>
                <span style={{ color: "#8d0788" }}>ZAMZAM v{REACT_APP_WEBAPP_VERSION}</span>
              </Typography>
              <Typography style={{ textAlign: "center" }}>
                Current build version {REACT_APP_LATEST_BUILD_VERSION}<br />
                Latest build on {REACT_APP_LATEST_BUILD_TIMESTAMP}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                {/* <Box
               // component="form"
               // noValidate
               // onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              > */}
                <Controller
                  name={"username"}
                  control={control}
                  rules={{ required: { value: true, message: 'Harus diisi' } }}
                  defaultValue=""
                  render={({ field: { value, ...field } }) => (
                    <TextField
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                      margin="normal"
                      id="tusername"
                      fullWidth
                      required
                      error={!!errors.password}
                      label="User Name"
                      size="small"
                      value={value || ""}
                      type="text"
                      helperText={errors?.username ? errors?.username.message : null}

                    />
                  )}
                />
                <Controller
                  name={"password"}
                  control={control}
                  rules={{ required: { value: true, message: 'Harus diisi' } }}
                  defaultValue=""
                  render={({ field: { value, ...field } }) => (
                    <TextField
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsOutlinedIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      id="tpassword"
                      margin="normal"
                      fullWidth
                      required
                      error={!!errors.password}
                      label="Password"
                      size="small"
                      value={value || ""}
                      type={showPassword ? "text" : "password"}
                      helperText={errors?.password ? errors?.password.message : null}

                    />
                  )}
                />

                {/*   <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />  */}<br></br><br></br>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3, mb: 2, backgroundColor: "#870144",
                    ':hover': {
                      bgcolor: '#b8045e',
                      color: 'white',
                    }
                  }}
                // onClick={handleSubmit(onSubmit, onError)}
                >
                  {isSubmitting ? 'Log In...' : 'Log In'}
                </Button>
              </form>
              {/* <Typography variant="body2">
                    version : {REACT_APP_WEBAPP_VERSION}
                </Typography> */}
              <Grid container pt="3em">

                {/* <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid> */}
              </Grid>
              {/*   </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Container>
  );
}

export default SignInSide;