import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  DoughnutController,
  ArcElement,
  LineController,
  BarController
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  DoughnutController,
  ArcElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
);

// Configure default options for all charts
ChartJS.defaults.datasets.bar.borderRadius = 5;
ChartJS.defaults.datasets.bar.borderWidth = 2;
ChartJS.defaults.datasets.bar.borderSkipped = false;
